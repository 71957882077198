var scrollY = function(){
    var supportPageOffset = window.pageXOffset !== undefined;
    var isCSS1Compat = ((document.compatMode || "") === "CSS1Compat");

    return supportPageOffset ? window.pageYOffset : isCSS1Compat ? document.documentElement.scrollTop : document.body.scrollTop;

};

var banner = document.querySelector(".banner"),
    body = document.querySelector("body"),
    header = document.querySelector(".top-head"),
    sidebar = document.querySelector('.sidebar'),
    topMenu = banner.getBoundingClientRect().top + scrollY(),
    rect = banner.getBoundingClientRect(),
    fake = document.createElement('div'),
    sidebarRow = document.querySelector('.sidebar-row'),
    postContainer = document.querySelector('.post-container');

//Fake element to right height
fake.style.height = rect.height + "px";
fake.style.width = rect.width + "px";

var topNav = function () {
    var hasClassFixed = banner.classList.contains('fixed');
    if (scrollY() > topMenu && !hasClassFixed) {
        banner.classList.add("fixed");
        banner.parentNode.insertBefore(fake, banner);

    } else if (scrollY() <= topMenu && hasClassFixed) {
        banner.classList.remove("fixed");
        banner.parentNode.removeChild(fake);
    }
};

window.addEventListener("scroll", topNav );

//Responsive nav
var button = document.querySelector('.navbar-toggler');
var responsive = function() {
    var logo = document.querySelector('.navbar-brand'),
        meditreso = document.querySelector('.meditreso-account'),
        reserved = document.querySelector('.reserved-account'),
        navbarTop = document.querySelector('.top-nav'),
        navbar = document.querySelector('.navbar-collapse');

    navbarTop.style.display = "none";
    logo.style.visibility = "visible";


    if(!navbar.classList.contains('show')){
        logo.style.visibility = "hidden";
        navbarTop.style.display = "block";
    }
    else {
        logo.style.visibility = "visible";
        navbarTop.style.display = "hidden";
    }
};
button.addEventListener("click", responsive );

var availableHomePosts, homeProfessionsSelect, simulatorFullSelect;

function shuffle(array) {
  var counter = array.length;
  // While there are elements in the array
  while (counter > 0) {
    // Pick a random index
    var index = Math.floor(Math.random() * counter);
    // Decrease counter by 1
    counter--;
    // And swap the last element with it
    var temp = array[counter];
    array[counter] = array[index];
    array[index] = temp;
  }
  return array;
}

/* ARTICLES ON HOME */
function extractPostsByCategories(subject) {
  var output = {};
  var articles = document.querySelectorAll('.articles-container.articles-home' + subject);
  articles = [].slice.call(articles);
  articles.forEach(function(el) {
    var classMatch = el.className.match(/pro([0-9]+)/);
    if (classMatch && classMatch[1]) {
      cat = classMatch[1];
      output[cat] = output[cat] || [];
      output[cat].push(el);
    }
  });
  return output;
}

function getAllPosts(posts, excludedCategory) {
  var output = [];

  for(var key in posts) {
    if( excludedCategory && excludedCategory === key ) {
      continue;
    }
    output.push.apply(output, posts[key]);
  }

  return output;
}

function getRandomPosts(limit, excludedCategory) {
  var posts = shuffle(getAllPosts(thematicPosts, excludedCategory));
  return posts.slice(0, limit);
}

function getPostsByCategory(category, limit) {
  var output  = [];
  var index   = 0;
  var posts   = availableHomePosts[category];

  if(!posts) {
    return output;
  }

  if(!limit) {
    return posts;
  }

  [].forEach.call(posts, function(post) {
    if(index < limit) {
      output.push(post);
    }
    index++;
  });

  return output;
}

function showPosts(posts) {
  [].forEach.call(posts, function(post) {
    post.style.display = 'block';
  });
}

function mergeArrays(left, right) {
  [].forEach.call(right, function(entry) {
    left.push(entry);
  });

  return left;
}

function homeSelectProfessionHandler() {
  var category = homeProfessionsSelect.options[homeProfessionsSelect.selectedIndex].value.replace('pro', '');
  if(category !== 0) {
    [].slice.call(document.querySelectorAll('.articles-container.articles-home')).forEach(function(el) {
      el.style.display = 'none';
    });

    var postsToShow = getPostsByCategory(category, 5);
    if(postsToShow.length < 5) {
      postsToShow = mergeArrays(postsToShow, getRandomPosts(5 - postsToShow.length , category) );

    }
    showPosts( postsToShow );

  }
}

availableHomePosts = extractPostsByCategories("");
thematicPosts = extractPostsByCategories(".theme");
homeProfessionsSelect = document.querySelector(".home-professions-select");
/**
 * Update list posts profession home page.
 */
if (homeProfessionsSelect) {
    homeProfessionsSelect.addEventListener("change", homeSelectProfessionHandler);
}

// Filter content with a select (hide all content except option selected content.)
var hideContentExceptSelected = function() {
    var navBySelect = document.querySelector(".js-navbyselect");
    if (navBySelect) {

        var showClasses = document.querySelectorAll("." + navBySelect.options[navBySelect.selectedIndex].value) || document.querySelectorAll("." + navBySelect.options[0].value);
        if (showClasses) {
            // Hide all not selected class
            [].forEach.call(showClasses, function(showClass) {
                var siblings = showClass.parentNode.children;

                [].forEach.call(siblings, function(sibling) {
                    if(showClass.tagName === sibling.tagName) { // only sisters pages with same tag
                        sibling.style.display = "none";
                    }
                });
            });
            // Show all selected class
            [].forEach.call(showClasses, function(showClass) {
                showClass.style.display = "block";
            });
        }
        navBySelect.addEventListener("change", hideContentExceptSelected);
    }
};
document.addEventListener("DOMContentLoaded", hideContentExceptSelected);

// Select used as menu
var navBySelectUrl = document.querySelector(".js-navbyselect-url");
var changePageWithSelect = function() {
    if(navBySelectUrl) {
        window.location.href = navBySelectUrl.options[navBySelectUrl.selectedIndex].value;
    }
};
if(navBySelectUrl) {
    navBySelectUrl.addEventListener("change", changePageWithSelect);
}

// Show a limited number of articles
var showLimitedArticles = function(limit) {
    [].forEach.call(document.querySelectorAll(".articles-home"), function(article_home) {
        article_home.style.display = "none";
    });
    i = 0;
    [].forEach.call(document.querySelectorAll(".js-first_pro_article"), function(first_article) {
        if (i< limit) {
            first_article.style.display = "block";
            i++;
        }
    });
};

document.addEventListener("DOMContentLoaded", function() {
    showLimitedArticles(5);
});

/* Placeholder-shown polyfill */
function placeholderPolyfill() {
  this.classList[this.value ? 'remove' : 'add']('placeholder-shown');
}
var placeholders = document.querySelectorAll('[placeholder]');
placeholders = [].slice.call(placeholders);
placeholders.forEach(function (el) {
  if (!el.value) {
    el.classList.add("placeholder-shown");
  }
  el.addEventListener('change', placeholderPolyfill);
  el.addEventListener('keyup', placeholderPolyfill);
});


function integer_only(field){
  var authorized_chars = new RegExp("[0-9]");
  if (field) {
    var fieldValue = field.value.split("");
    var newFieldValue = fieldValue.filter(function(char){
      return authorized_chars.test(char);
    });
    field.value = newFieldValue.join("");
  }
}

(function($) {

    // Informations on calendar
    $('.em-calendar-wrapper').delegate('.eventful a, .eventful-today a', 'click', function(){
        // Selected style
        $(".eventful, .eventful-today").removeClass("selected-event");
        $(this).parent().addClass("selected-event");
        // Writes content
        $(".event-categories li a").html($(this).attr("data-cat"));
        $(".calendar-event-details").html(
            "<span class='calendar-event-day'>" + $(this).attr("data-date") + "</span><br>" + $(this).attr("title")
        );
    });

    $("#videos_list").change(function() {
        var vid = $(this)[0].selectedIndex;
        for (var player in mejs.players) {
            mejs.players[player].media.stop();
            mejs.players[player].media.currentTime = 0;
        }
        $(".video_container").css("display", "none").eq(vid).css("display", "block");
    });

    // Target blank for external links
    $('a').each(function() {
      var a = new RegExp('/' + window.location.host + '/');
      if (!a.test(this.href)) {
        $(this).attr("target","_blank");
      }
    });
    // Target blank for pdf
    $('a[href$=".pdf"]').prop('target', '_blank');

    $('input[type="number"]').on('input', function() {
      integer_only(this);
    });

})(jQuery);
